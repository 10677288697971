<template>
  <v-card target="_blank" :href="'https://drive.google.com/file/d/' + id + '/view'" elevation="0">
    <v-img :src="imageData" width="140" height="160">
      <div style="background-color:rgba(0, 0, 0, .8);color:white;position:absolute;bottom:0;width:100%;text-align:center">{{ name }}</div>
    </v-img>
  </v-card>
</template>
<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    filetype: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const imageData = ref(null)

    watch(() => props.id, async () => {
      // If the fileType is an image, load the image
      if (props.id != null && props.id !== '' && props.filetype.substring(0, 5) === 'image') {
        try {
          const { data } = await root.$feathers.service('service/drive-upload').get(props.id, { query: { download: 'Y' } })
          imageData.value = data
          console.log(data)
        } catch (e) {
          console.error(e)
        }
      }
    })
    return {
      imageData
    }
  }
}
</script>
